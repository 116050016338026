import { ThemeProvider, createMuiTheme } from '@material-ui/core';
import { Provider } from 'react-redux';
import { ascensionTheme } from '@ascension/ui-library';
import { store } from '../redux/store';
import AuthProvider from '../auth/AuthProvider';
import AppRouter from './AppRouter';
import './interceptor';
import '@ascension/ui-library/dist/fonts/WhitneySSm.css';
import '@ascension/ui-library/dist/fonts/ChronicleSSm.css';

const theme = createMuiTheme(ascensionTheme);

function App() {

  return (
    <>
      <AuthProvider>
        <Provider store={store}>
          <ThemeProvider theme={theme}>
            <AppRouter />
          </ThemeProvider>
        </Provider>
      </AuthProvider>
    </>
  );
}

export default App;
