import axios from 'axios';
import moment from 'moment';
import jwt_decode from "jwt-decode";

class Interceptor {
   constructor(Auth) {
      this.auth = Auth;
      this.init();
   }

   init() {
      // For GET requests
      axios.interceptors.request.use(
         (req) => {
            // Add configurations here
            const currentUnixStamp = moment().unix();
            const isBearer = req.headers.Authorization.includes('Bearer');
            if(isBearer) {
               const tokenValidTime = jwt_decode(req.headers.Authorization).exp;
               if ((tokenValidTime - currentUnixStamp) < 1) {
                  this.auth.logout();
               }
            }
            return req;
         },
         (err) => {
            return Promise.reject(err);
         }
      );

      // For POST requests
      axios.interceptors.response.use(
         (res) => {
            return res;
         },
         (err) => {
            return Promise.reject(err);
         }
      );
   }
}

export default Interceptor;

