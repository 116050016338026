const constants = {
    SET_PERSONAL_INFORMATION: 'SET_PERSONAL_INFORMATION',
    SET_PERSONAL_INFO: 'SET_PERSONAL_INFO',
    SET_PERSONAL_INFO_ERROR: 'SET_PERSONAL_INFO_ERROR',
    SET_SENSITIVE_INFO: 'SET_SENSITIVE_INFO',
    SET_SENSITIVE_INFO_ERROR: 'SET_SENSITIVE_INFO_ERROR',

    SET_STATE_LIST: 'SET_STATE_LIST',
    SET_GENDER_LOOKUP: 'SET_GENDER_LOOKUP',
    SET_PHONE_NUMBER: 'SET_PHONE_NUMBER',
    SET_VERIFICATION_CODE: 'SET_VERIFICATION_CODE',
    SET_UPDATED_PHONE_NUMBER: 'SET_UPDATED_PHONE_NUMBER'
};

export default constants;
