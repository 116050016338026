import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import routes from '../../constants/routes';

const useStyles = makeStyles(() => ({
  root: {
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#dadada'
  },
  paper: {
    padding: '16px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end'
  }
}));

export const Logout = ({ history }) => {
  const classes = useStyles();

  const clearLocalTokens = useCallback(() => {
    const keys = Object.keys(window.localStorage).filter(x =>
      x.includes('"authority"')
    );

    keys.forEach(x => window.localStorage.removeItem(x));
  }, []);

  useEffect(() => {
    clearLocalTokens();
  }, [clearLocalTokens]);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <h4>You have successfully signed out from this device.</h4>
        <Button
          variant="contained"
          color="primary"
          onClick={() => history.push(routes.HOME_PAGE)}
        >
          go to home page
        </Button>
      </Paper>
    </div>
  );
};

Logout.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired
};

export default Logout;
