import React, { createContext, useContext, useEffect, useState } from 'react';
import jwt_decode from 'jwt-decode';
import * as msal from '@azure/msal-browser';
import { passAuthority, msalInstance, scopes } from './auth-utils';
import { revokeToken } from '../utils/session-utils';

export const AuthContext = createContext(null);
export const useAuth = () => useContext(AuthContext);

const AuthProvider = ({ children }) => {
  const getActiveAccount = () => {
    const allAccounts = msalInstance.getAllAccounts();
    return allAccounts[0] || null;
  };

  const [account, setAccount] = useState(getActiveAccount());
  const [loading, setLoading] = useState(true);

  const isMock = process.env.REACT_APP_USE_MOCK === 'true';

  useEffect(() => {
    if (!isMock) {
      msalInstance
        .handleRedirectPromise()
        .then((res) => {
          if (res) {
            setAccount(res.account);
          }
          setLoading(false);
        })
        .catch(error => {
          console.log('Redirect error: ', error.message);
          setLoading(false);
          // Added to address an issue with the msal-brower lib (2.14.1).
          // If lib has been updated this might be able to be removed.
         if (error.errorCode === 'access_token_entity_null') {
           window.location = '/';
         }
        });
    }
  }, [isMock]);

  const aquireToken = async () => {
    const acquireTokenSilentArg = {
      scopes: scopes,
      account: account
    };
    return await msalInstance.acquireTokenSilent(acquireTokenSilentArg).catch(error => {
      if (error instanceof msal.InteractionRequiredAuthError) {
        console.log('InteractionRequiredAuthError');
        return msalInstance.acquireTokenRedirect(scopes);
      } else {
        console.log('Uncaught acquireTokenSilent error: ', error.message);
      }
    });
  }


  const login = async () => {
    try {
      return await msalInstance.loginRedirect(scopes);
    } catch (err) {
      console.log('Login Error: ', err.message);
    }
  };

  const logout = () => {
    revokeToken();
    return msalInstance.logout({
      account
    });
  }


  const ssoLogin = async () => {
    const cachedAccount = getActiveAccount();
    if (cachedAccount) {
      try {
        const loginResponse = await msalInstance.ssoSilent({
          scopes,
          loginHint:
            cachedAccount.idTokenClaims.email ||
            cachedAccount.idTokenClaims.signInName
        });
        setAccount(loginResponse.account);
        return loginResponse;
      } catch (err) {
        if (err instanceof msal.InteractionRequiredAuthError) {
          login();
        } else {
          console.log('Uncaught SSO Error: ', err.message);
        }
      }
    } else {
      console.info('Account not found, redirecting to Login.');
      login();
    }
  };

  const getRoles = accessToken => {
    if (!accessToken) return null;
    const { user_roles } = jwt_decode(accessToken);
    return user_roles;
  };

  const getAppId = (activeAccount) => {
    if (!activeAccount) return null;
    return activeAccount.idTokenClaims.aud
  };

  const didUserCancel = (err) => { return err.message.startsWith("user_cancelled") || err.message.includes("AADB2C90091") };
  const resetPassword = async (cancelAction, openBackdrop, closeBackdrop) => {
    openBackdrop();
    try {
      return await msalInstance.loginPopup({
        authority: passAuthority
      });
    } catch (err) {
      if(didUserCancel(err)) {
        cancelAction();
      }
      else { console.log("TODO Redirect to General Error Page") }
    } finally {
      closeBackdrop();
    }
  };

  return (
    <AuthContext.Provider
      value={{
        aquireToken: isMock
          ? () => new Promise(resolve => resolve(mock_token))
          : aquireToken,
        getRoles: isMock ? () => mock_token.user_roles : getRoles,
        login: isMock ? () => { } : login,
        loading: isMock ? false : loading,
        logout: isMock ? () => { } : logout,
        ssoLogin: isMock ? () => { } : ssoLogin,
        getAppId: isMock ? () => mock_token.aud : getAppId,
        account: isMock ? mock_account : account,
        resetPassword: isMock ? {} : resetPassword
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;

const mock_token = {
  name: 'User, Mock D',
  given_name: 'Mock',
  family_name: 'User',
  email: 'Mock.User@example.com',
  aud: 'mock clientId',
  user_roles: ['ASCENSION_ID_ADMIN'],
  user_scopes: [
    'ascid.apps.scopes.create',
    'ascid.apps.roles.create',
    'ascid.apps.appid.read',
    'ascid.apps.roles.delete',
    'ascid.users.create',
    'ascid.apps.roles.read',
    'ascid.roles.read',
    'ascid.apps.roles.update',
    'ascid.apps.appid.users.delete',
    'ascid.apps.appid.users.update',
    'ascid.apps.appid.users.create',
    'ascid.apps.appid.roles.delete',
    'ascid.apps.appid.roles.update',
    'ascid.apps.appid.roles.create',
    'ascid.apps.appid.roles.read',
    'ascid.apps.scopes.delete',
    'ascid.apps.scopes.update',
    'ascid.apps.scopes.read',
    'ascid.apps.users.delete',
    'ascid.apps.users.update',
    'ascid.apps.users.read',
    'ascid.users.update',
    'ascid.users.userid.read',
    'ascid.users.read',
    'ascid.roles.delete',
    'ascid.roles.update',
    'ascid.roles.create',
    'ascid.scopes.read',
    'ascid.scopes.delete',
    'ascid.scopes.update',
    'ascid.scopes.create',
    'ascid.apps.delete',
    'ascid.apps.update',
    'ascid.apps.create',
    'ascid.apps.read',
    'ascid.audit-log.read',
    'ascid.users.roles.delete',
    'ascid.users.delete',
    'ascid.apps.users.create'
  ]
};

const mock_account = {
  environment: 'ascensionidqa.b2clogin.com',
  homeAccountId: 'no-real-id',
  idTokenClaims: mock_token,
  localAccountId: 'no-real-id',
  name: 'User, Mock D',
  tenantId: 'no-real-id',
  username: ''
};
