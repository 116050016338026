import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import useInterval from '../../hooks/useInterval';

const useStyles = makeStyles((theme) => ({
  time: {
    color: "#B40F87"
  }
}));

export default ({ onExpire, resetTimer }) => {
  const classes = useStyles();
  const [currentTime, setCurrentTime] = useState("");

  useInterval(() => {
    const now = new Date().getTime();
    const distance = resetTimer - now;
    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((distance % (1000 * 60)) / 1000);
    const formattedMins = minutes < 10 ? `0${minutes}` : minutes;
    const formattedSecs = seconds < 10 ? `0${seconds}` : seconds;
    setCurrentTime(formattedMins + ":" + formattedSecs);

    if (distance < 0) {
      setCurrentTime("EXPIRED");
      onExpire();
    }
  }, 1000);

  return <span data-testid="time-disp" className={classes.time}>{currentTime}</span>;
}
