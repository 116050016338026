import { React, useContext } from 'react';
import {
  Drawer,
  List,
  ListItem
} from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import routes from '../../constants/routes';
import { makeStyles } from '@material-ui/core/styles';
import LockIcon from '@material-ui/icons/Lock';
import LogoutIcon from '@material-ui/icons/ExitToApp';
import Hidden from '@material-ui/core/Hidden';
import { useAuth } from "../../auth/AuthProvider";
import Typography from "@material-ui/core/Typography";
import { DrawerContext } from '../../context';

const useStyles = makeStyles((theme) => ({
  drawer: {
    flexShrink: 0,
  },
  drawerItem: {
    marginBottom: 8,
    backgroundColor: '#fff',
    fontFamily: 'Whitney',
    fontSize: '16px',
    borderLeft: '8px solid #fff',
    padding: 16,
    [theme.breakpoints.only('xs')]: {
      marginLeft: 7
    },
    '& > a': {
      fontSize: '14px',
    },
    '&:hover': {
      backgroundColor: 'rgba(30, 105, 210, 0.08)',
    },
    '&$selected': {
      color: '#1E69D2',
      backgroundColor: 'rgba(30, 105, 210, 0.08)',
      borderColor: '#1E69D2',
      fontSize: '18px',
      '& img': {
        color: '#1E69D2',
      }
    }
  },
  selected: {
    backgroundColor: 'brown'
  },
  inputSearchBox: {
    width: '85%',
    paddingLeft: '20px',
  },
  searchText: {
    fontSize: '12px',
  },
  drawerPaper: {
    position: 'fixed',
    width: '280px',
    [theme.breakpoints.only('xs')]: {
      width: '100%'
    },
    height: '1307px',
    left: '0px',
    top: '65px',
    paddingTop: 35,
    background: '#FFFFFF',
    fontFamily: 'HCo Whitney SSm',
    fontStyle: 'normal',
    fontWeight: '375',
    fontSize: '18px',
    lineHeight: '200%',
    display: 'flex',
    alignItems: 'center',
    letterSpacing: '0.15px',
    color: 'rgba(0, 0, 0, 0.87)',
  },
  listItemIcon: {
    color: 'rgba(0, 0, 0, 0.54)',
    display: 'inline-flex',
    minWidth: '40px',
    flexShrink: '0'
  },
  leftNav: {
    width: '100%',
    overflowY: 'auto',
    height: 'calc(100vh - 125px)',
    [theme.breakpoints.only('xs')]: {
      marginTop: 10
    }
  },
  listItemTextInset: {
    paddingLeft: '24px'
  }
}));

const DrawerChild = ({
  history,
  active,
  toggleOnClick
}) => {
  const classes = useStyles();
  const {setOpenDrawer} = useContext(DrawerContext);
  const { logout } = useAuth();
  /**
    Note:
    It is decided to hide `Personal information` side menu tentatively
    If this decision is reverted, please add the object below to `sideMenuConfig` list
    @code
      {
        name: 'Personal information',
        icon: PersonIcon,
        onSelect: () => { history.push(routes.HOME_PAGE) }
      }
    @author gheo
   */
  const sideMenuConfig = [{
    name: 'Login and security',
    icon: LockIcon,
    onSelect: () => { history.push(routes.HOME_PAGE) }
  }, {
    name: 'Log out',
    icon: LogoutIcon,
    onSelect: () => { logout() }
  }];
  return <List className={classes.leftNav}>
    {sideMenuConfig.map((item, index) => (
      <ListItem
        className={classes.drawerItem}
        key={`${item}-${index}`}
        button
        onClick={() => {
          if (toggleOnClick) {
            setOpenDrawer(false);
          }
          if (typeof item.onSelect === "function") {
            item.onSelect();
          }
        }}
        selected={item.name === active}
        classes={{
          root: classes.drawerItem,
          selected: classes.selected
        }}
      >
        <item.icon />
        <Typography variant={'body1'} className={classes.listItemTextInset}>
          {item.name}
        </Typography>
      </ListItem>
    ))}
  </List>
}

const SideNav = (props) => {
  const {openDrawer} = useContext(DrawerContext);
  const classes = useStyles();
  
  return (
    <nav className={classes.drawer} aria-label="mailbox folders">
      <Hidden smUp implementation="css">
        <Drawer
          variant="persistent"
          open={openDrawer}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true
          }}
        >
          <DrawerChild {...props} toggleOnClick />
        </Drawer>
      </Hidden>
      <Hidden xsDown implementation="css">
        <Drawer
          classes={{
            paper: classes.drawerPaper,
          }}
          variant="permanent"
          open
        >
          <DrawerChild {...props} />
        </Drawer>
      </Hidden>
    </nav>
  );
};
export default withRouter(SideNav);
