export const authConfig = {
  dev: {
    applicationId: '318a4c34-4cb3-46bb-8aa1-8cc055d80b03',
    authority:
      'https://ascensioniddv.b2clogin.com/ascensioniddv.onmicrosoft.com/B2C_1A_signup_signin_member',
    scopes: [
      'https://ascensioniddv.onmicrosoft.com/ascensionid-user-profile-api/ascid.up.own.genericdata.manage',
      'https://ascensioniddv.onmicrosoft.com/ascensionid-user-profile-api/ascid.up.own.genericdata.read',
      'https://ascensioniddv.onmicrosoft.com/ascensionid-user-profile-api/ascid.up.own.sensitivedata.manage',
      'https://ascensioniddv.onmicrosoft.com/ascensionid-user-profile-api/ascid.up.own.sensitivedata.read',
      'https://ascensioniddv.onmicrosoft.com/ascensionid-user-profile-api/app.access'
    ]
  },
  qa: {
    applicationId: '182cdaf0-1521-4cd2-8819-7c0313755c91',
    authority:
      'https://ascensionidqa.b2clogin.com/ascensionidqa.onmicrosoft.com/B2C_1A_signup_signin_member',
    scopes: [
      'https://ascensionidqa.onmicrosoft.com/ascensionid-user-profile-api/ascid.up.own.genericdata.read',
      'https://ascensionidqa.onmicrosoft.com/ascensionid-user-profile-api/ascid.up.own.genericdata.manage',
      'https://ascensionidqa.onmicrosoft.com/ascensionid-user-profile-api/ascid.up.own.sensitivedata.manage',
      'https://ascensionidqa.onmicrosoft.com/ascensionid-user-profile-api/ascid.up.own.sensitivedata.read',
      'https://ascensionidqa.onmicrosoft.com/ascensionid-user-profile-api/app.access'
    ]
  },
  userAcceptance: {
    applicationId: '17ff4605-9c0a-4f5d-ae56-7659e64597ef',
    authority:
      'https://id-ua.ascension.org/ascensionidua.onmicrosoft.com/B2C_1A_signup_signin_member',
    scopes: [
      'https://id-ua.ascension.org/ascensionid-user-profile-api/ascid.up.own.genericdata.read',
      'https://id-ua.ascension.org/ascensionid-user-profile-api/ascid.up.own.genericdata.manage',
      'https://id-ua.ascension.org/ascensionid-user-profile-api/ascid.up.own.sensitivedata.manage',
      'https://id-ua.ascension.org/ascensionid-user-profile-api/ascid.up.own.sensitivedata.read',
      'https://id-ua.ascension.org/ascensionid-user-profile-api/app.access'
    ]
  },
  prod: {
    applicationId: '8e82ffd3-68c4-4056-98c8-44d33003ec4c',
    authority:
      'https://id.ascension.org/id.ascension.org/B2C_1A_signup_signin_member',
    scopes: [
      'https://id.ascension.org/ascensionid-userprofile-api/ascid.up.own.genericdata.read',
      'https://id.ascension.org/ascensionid-userprofile-api/ascid.up.own.genericdata.manage',
      'https://id.ascension.org/ascensionid-userprofile-api/ascid.up.own.sensitivedata.manage',
      'https://id.ascension.org/ascensionid-userprofile-api/ascid.up.own.sensitivedata.read',
      'https://id.ascension.org/ascensionid-userprofile-api/app.access'
    ]
  }
};

export const baseApiUrls = {
  local:
    'http://localhost:8080/api/v1',
  dev:
    'https://dev-api.ascension.org/user-profile-api',
  qa:
    'https://test-api.ascension.org/user-profile-api',
  uat:
    'https://uat-api.ascension.org/user-profile-api',
  prod: 
    'https://prod-api.ascension.org/user-profile-api'
};

export const profileApiKeys = {
  nonProd:
    'ms9Y8WxxGxFGOnQ31y9Kr69rAimr8pDI',
  prod:
    'botLN8XqeLkKbeLls4qWAfIx6MxQx3US'
};

export const resetPasswordAuthority = {
  local:
      'https://ascensioniddv.b2clogin.com/ascensioniddv.onmicrosoft.com/B2C_1A_PASSWORD_RESET',
  dev:
      'https://ascensioniddv.b2clogin.com/ascensioniddv.onmicrosoft.com/B2C_1A_PASSWORD_RESET',
  qa:
      'https://ascensionidqa.b2clogin.com/ascensionidqa.onmicrosoft.com/B2C_1A_PASSWORD_RESET',
  uat:
      'https://id-ua.ascension.org/ascensionidua.onmicrosoft.com/B2C_1A_PASSWORD_RESET',
  prod:
      'https://id.ascension.org/id.ascension.org/B2C_1A_PASSWORD_RESET'
};

export const getEnvVars = host => {
  const prodRegex = /^(?!dev|qa|uat|localhost)profile.ascension.org/gm;
  switch (true) {
    case host.includes('localhost'):
      return { apiUrl: baseApiUrls.dev, authConfig: authConfig.dev, resetPassAuthority:resetPasswordAuthority.local,apiKey:profileApiKeys.nonProd };
    case host.includes('dev'):
      return { apiUrl: baseApiUrls.dev, authConfig: authConfig.dev, resetPassAuthority:resetPasswordAuthority.dev,apiKey:profileApiKeys.nonProd };
    case host.includes('qa'):
      return { apiUrl: baseApiUrls.qa, authConfig: authConfig.qa, resetPassAuthority:resetPasswordAuthority.qa ,apiKey:profileApiKeys.nonProd};
    case host.includes('uat'):
      return { apiUrl: baseApiUrls.uat, authConfig: authConfig.userAcceptance, resetPassAuthority:resetPasswordAuthority.uat,apiKey:profileApiKeys.nonProd};
    case host.includes('pub.cloud-03.pcf.ascension.org') || prodRegex.exec(host) != null:
      return { apiUrl: baseApiUrls.prod, authConfig: authConfig.prod, resetPassAuthority:resetPasswordAuthority.prod,apiKey:profileApiKeys.prod};
    default:
      console.error('Failed to get env config!');
      return null;
  }
};
