import axios from 'axios';
import constants from '../../constants/redux.constants';
import { getToken } from '../../utils/session-utils';
import { getEnvVars } from '../../utils/env-utils';

const { apiUrl } = getEnvVars(window.location.hostname);
const { apiKey } = getEnvVars(window.location.hostname);
const { SET_STATE_LIST, SET_PHONE_NUMBER, SET_VERIFICATION_CODE, SET_UPDATED_PHONE_NUMBER } = constants;

export const setStateList = payload => ({
  type: SET_STATE_LIST,
  payload
});

export const setPhoneNumber = payload => ({
  type: SET_PHONE_NUMBER,
  payload
});

export const setUpdatedPhoneNumber = payload => ({
  type: SET_UPDATED_PHONE_NUMBER,
  payload
});

export const setVerificationCode = payload => {
  return {
    type: SET_VERIFICATION_CODE,
    payload
  }
};

export const getStateList = (countryCode="US") => {
  const accessToken = getToken();
  return dispatch => {
    return axios
      .get(`${apiUrl}/v1/lookups/countries/${countryCode}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          apiKey: `${apiKey}`
        }
      }).then(
        (res) => dispatch(setStateList(res?.data?.lookupItems[countryCode]?.States || [])),
        (error) => dispatch(setStateList([]))
      );
  };
}

export const getPhoneNumbers = (userid) => {
  const accessToken = getToken();
  return dispatch => {
    return axios
      .get(`${apiUrl}/v1/b2c/profiles/${userid}/phones`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          apiKey: `${apiKey}`
        }
      }).then(
        (res) => dispatch(setPhoneNumber(res?.data || [])),
        (error) => dispatch(setPhoneNumber([]))
      );
  };
}

export const sendVerificationCode = (phoneNumber, successCb, errorCb) => {
  const accessToken = getToken();
  return dispatch => {
    return axios
      .post(`${apiUrl}/v1/b2c/verification/${phoneNumber}/phone`, {}, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          apiKey: `${apiKey}`
        }
      }).then(
        (res) => {
          dispatch(setVerificationCode(res?.data?.verificationCode || ""));
          dispatch(setUpdatedPhoneNumber(phoneNumber || ""));
          if (typeof successCb === 'function') {
            successCb(res);
          }
        },
        (error) => {
          dispatch(setVerificationCode(""));
          if (typeof errorCb === 'function') {
            errorCb(error);
          }
        }
      );
  };
}

export const addPhoneNumbers = (payload, userid,successCb, errorCb) => {
  const accessToken = getToken();
  return dispatch => {
    return axios
      .post(`${apiUrl}/v1/b2c/profiles/${userid}/phones-azure-and-profile`, payload, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          apiKey: `${apiKey}`
        }
      }).then(
        (res) => {
          console.log("in success {}",res?.data);
          dispatch(setPhoneNumber([res?.data] || []))
          successCb(res);
        },
        (error) => {
          console.log("in Fail {}",error);
          dispatch(setPhoneNumber([]))
          errorCb(error);
        }
      );
  };
}

