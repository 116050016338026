import constants from '../../constants/redux.constants';

const initialState = {
  stateList: undefined,
  phoneDetails: [],
  updatedPhoneNumber: null,
  verificationCode: ""
};

export default (state = initialState, { type, payload }) => {
  const { SET_STATE_LIST, SET_PHONE_NUMBER, SET_VERIFICATION_CODE, SET_UPDATED_PHONE_NUMBER } = constants;
  switch (type) {
    case SET_STATE_LIST:
      return {
        ...state,
        stateList: [...payload]
      };
    case SET_PHONE_NUMBER:
      return {
        ...state,
        phoneDetails: [...payload]
      };
    case SET_UPDATED_PHONE_NUMBER:
      return {
        ...state,
        updatedPhoneNumber: payload || state.updatedPhoneNumber
      };
    case SET_VERIFICATION_CODE:
      return {
        ...state,
        verificationCode: payload || ""
      };
    default:
      return state;
  }
};
