import { combineReducers } from 'redux';
import personalInformationReducer from './personalInformation/reducer';
import commonReducer from './common/reducer';

const appReducer = combineReducers({
    personalInfo: personalInformationReducer,
    common: commonReducer
});

export default (state, action) => {
    return appReducer({ ...state }, action);
};
