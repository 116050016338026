import { PublicClientApplication } from '@azure/msal-browser';
import { getEnvVars } from '../utils/env-utils';

const { authConfig, resetPassAuthority } = getEnvVars(window.location.hostname);

export const msalInstance = new PublicClientApplication({
  auth: {
    clientId: authConfig.applicationId,
    redirectUri: `${window.location.origin}/`,
    authority: authConfig.authority,
    knownAuthorities: [authConfig.authority]
  },
  system: {
    loadFrameTimeout: 60000,
    windowHashTimeout: 60000,
    iframeHashTimeout: 60000
  },
  cache: { cacheLocation: 'localStorage', storeAuthStateInCookie: false }
});

export const scopes = authConfig.scopes;
export const passAuthority = resetPassAuthority;
