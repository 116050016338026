import constants from '../../constants/redux.constants';

const initialState = {
  activeRole: null,
  personInfo: null,
  personInfoErr: {
    isError: false,
    errMsg: ''
  },
  sensitiveInfo: null,
  sensitiveInfoErr: {
    isError: false,
    errMsg: ''
  },
  genderLookup: null
};

export default (state = initialState, { type, payload }) => {
  const { SET_PERSONAL_INFORMATION, SET_PERSONAL_INFO, SET_PERSONAL_INFO_ERROR, SET_SENSITIVE_INFO, SET_SENSITIVE_INFO_ERROR, SET_GENDER_LOOKUP } = constants;
  switch (type) {
    case SET_PERSONAL_INFORMATION:
      return {
        ...state,
        activeRole: payload
      };
    case SET_PERSONAL_INFO:
      return {
        ...state,
        personInfo: payload?.data,
        personInfoErr: {
          isError: initialState.isError,
          errMsg: initialState.errMsg
        }
      };
    case SET_PERSONAL_INFO_ERROR:
      return {
        ...state,
        personInfo: {},
        personInfoErr: {
          isError: true,
          errMsg: payload
        }
      };
    case SET_SENSITIVE_INFO:
      return {
        ...state,
        sensitiveInfo: payload?.data,
        sensitiveInfoErr: {
          isError: initialState.isError,
          errMsg: initialState.errMsg
        }
      };
    case SET_SENSITIVE_INFO_ERROR:
      return {
        ...state,
        sensitiveInfo: {},
        sensitiveInfoErr: {
          isError: true,
          errMsg: payload
        }
      };
    case SET_GENDER_LOOKUP:
      return {
        ...state,
        genderLookup: payload
      };
    default:
      return state;
  }
};
