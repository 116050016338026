/* Functions for validating user input */
export const isValidUrl = (url) => (
  url != null &&
  /^https:\/\//.test(url)
)

export const isValidUrlArray = (ary) => {
  let valid = true;
  for (const url of ary) {
    valid = valid && isValidUrl(url);
  }
  return valid;
}


export const isValidField = (text) => (
  text != null && /\w/.test(text)
)

export const isValidEmail = (text) => (
  text != null && /\w@\w/.test(text)
)

export const formattedPhoneNum = (phoneNumber) => {
  const phNum = phoneNumber || '';
  if (!phNum) {
      return '';
  }

  return phoneNumber;
};

export const formattedPhoneNumber = (phoneNumber) => {
  const phNum = phoneNumber || '';
  if (!phNum) {
    return 'None';
  }
  const nospaceNum = phNum.replace(/\s/g, '');
  const prefix = nospaceNum.includes('+1') ? '' : '';
  const rawNumber = nospaceNum.replace('+1', '');

  return `${prefix} (${rawNumber.slice(0,3)})-${rawNumber.slice(3,6)}-${rawNumber.slice(6)}`;
};