import React, { useState, useEffect, cloneElement } from 'react';
import {
  Card,
  Button
} from '@material-ui/core';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import Typography from "@material-ui/core/Typography";
import { PersonalInfoContext } from '../../../context';
const useStyles = makeStyles((theme) => ({
  root: {
    padding: 24
  },
  cardTitle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 24,

  },
  title: {
    fontSize: 24,
    color: 'rgba(0, 0, 0, 0.87)',
    margin: 0
  },
  cardAction: {
    padding: '11.65px 9px',
    fontFamily: 'HCo Whitney SSm',
    fontSize: 14,
    '@media (max-width: 320px)': {
      marginRight: -10,
      fontSize: 11
    }
  },
  cancelBtn: {
    color: '#E00019',
    fontSize: 14,
    '@media (max-width: 320px)': {
      marginRight: -10,
      fontSize: 11
    }
  },
  actionRow: {
    marginTop: 20,
    width: "-webkit-fill-available",
    textAlign: "center",
  },

}));

export default ({ mode, cardTitle, displayComponent, editComponent, disabled, clickListener, hideButton, onModeChange }) => {
  const classes = useStyles();
  const [chosenMode, setChosenMode] = useState(mode);
  let startIcon;
  let btnTxt;
  let btnVariant = 'outlined';

  const toggleMode = () => {
    if (typeof clickListener === 'function') {
      clickListener(() => setChosenMode(mode), btnTxt, mode)
    } else {
      const customMode = chosenMode === 'Update' ? mode : 'Update';
      setChosenMode(customMode);
    }
    if (typeof onModeChange === 'function') {
      onModeChange(btnTxt);
    }
    const customMode0 = chosenMode === 'Update' ? mode : 'Update';
    const customMode1 = chosenMode === 'ChangePassword' ? 'ChangePassword' : customMode0;
    setChosenMode(customMode1);
  };

  const setEditMode = () => {
    setChosenMode('Update');
  }

  const setMode = (mode) => {
    setChosenMode(mode);
  }

  switch (chosenMode) {
    case "New":
      startIcon = <AddIcon />;
      btnTxt = 'Add';
      btnVariant = 'outlined';
      break;
    case "Edit":
      startIcon = <EditIcon />;
      btnTxt = 'Edit';
      btnVariant = 'text';
      break;
    case "Update":
      startIcon = <></>;
      btnTxt = 'Cancel';
      btnVariant = 'text';
      break;
    case "ChangePassword":
      startIcon = <></>;
      btnTxt = 'Change Password';
      btnVariant = 'text';
      break;
    default:
      startIcon = <AddIcon />;
      btnTxt = 'Add';
      btnVariant = 'outlined';
  }

  useEffect(() => {
    setChosenMode(mode);
  }, [mode]);

  return (
    <PersonalInfoContext.Provider value={{
      setEditMode,
      setMode
    }}>
      <Card className={classes.root} variant="outlined">
        <div className={classes.cardTitle}>
          {cardTitle &&
          <Typography variant="h5" className={classes.title} key={`${cardTitle}-Card`}>
            {cardTitle}
          </Typography>
          }
          {
            !hideButton && <Button
              variant={btnVariant}
              color="primary"
              size="small" showButton
              className={clsx({
                [classes.cardAction]: true,
                [classes.cancelBtn]: (chosenMode === "Update"),
              })}
              startIcon={startIcon}
              onClick={() => toggleMode()}
              disabled={disabled}
            >
              {btnTxt}
            </Button>
          }


        </div>

        {chosenMode === "Update" && editComponent && cloneElement(
          editComponent,
          { updateCardMode: setChosenMode }
        )}
        {chosenMode !== "Update" && displayComponent}

      </Card>
    </PersonalInfoContext.Provider>
  );
};