import React, { useEffect, useState } from "react";
import clsx from 'clsx';
import jwt_decode from "jwt-decode";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { useAuth } from '../../auth/AuthProvider';
import { useSelector, useDispatch } from 'react-redux';
import CardInfo from "../PersonalInformation/components/CardInfo";
import SideNav from "../../components/SideNav/SideNav";
import TwofactorEdit from "../../components/TwofactorEdit/TwofactorEdit";
import NotificationBanner from '../../components/NotificationBanner/NotificationBanner';
import Greentick from '../../assets/green-tick.svg';
import { getPhoneNumbers } from '../../redux/common/actions';
import { formattedPhoneNumber } from '../../utils/test-utils';
import { useAppToken, LoginNotificationContext } from '../../context';
import { setUpdatedPhoneNumber } from "../../redux/common/actions";
import Grid from "@material-ui/core/Grid";
import {Backdrop} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: '48px 8%',
        minWidth: 500,
        minHeight: 440,
        flexGrow: 1,
        fontFamily: 'HCo Whitney SSm',
        [theme.breakpoints.down("xs")]: {
            maxWidth: 320,
            minWidth: 320,
            marginTop: 20,
          },
          '@media (max-width: 320px)': {
            maxWidth: 280,
            minWidth: 280,
            marginTop: 20,
          }  
    },
    main: {
        marginLeft: 0,
        [theme.breakpoints.up('sm')]: {
          marginLeft: '240px !important'
        }
    },
    header: {
        fontSize: '2rem',
        marginBottom: '16px',
    },
    tableContainer: {
        minHeight: '100px',
        maxHeight: '70vh',
        borderBottom: 0,
    },
    title: {
        marginTop: 56,
        fontStyle: 'normal',
        fontSize: 32,
        color: 'rgba(0, 0, 0, 0.87)'
    },
    container: {
        display: 'block',
        width: '100%',
        left: '498px',
        marginTop: '25px',
        borderRadius: '3px',
        border: '0.5px solid rgba(0, 0, 0, 0.12)',
        boxSizing: 'border-box',
        boxShadow: '1px 1px 1px rgb(0 0 0 / 7%), 0px 2px 1px rgb(0 0 0 / 6%), 0px 1px 3px rgb(0 0 0 / 10%)',
        background: '#FFFFFF',
    },
    description: {
        fontSize: 16,
        lineHeight: '24px',
        color: 'rgba(0, 0, 0, 0.6)',
    },
    eachCard: {
        margin: "32px 0"
    },
    subtitle: {
        fontSize: 16
    },
    subtxt: {
        fontStyle: 'normal',
        fontWeight: 350,
        color: 'rgba(0, 0, 0, 0.6)'
    },
    setTop: {
        marginTop: 24
    },
    greenTick: {
        marginRight: 9
    },
    alignCenter: {
        display: 'flex'
    },
    emptyCircle: {
        display: 'inline-block',
        width: 20,
        height: 20,
        borderRadius: 50,
        border: '1px solid #ccc',
        marginRight: 9
    }
}));

const TwoFactorComponent = ({ mobileDetail = {} }) => {
    const classes = useStyles();
    return (
        <>
            <Typography variant="h6" className={clsx(classes.subtitle, classes.alignCenter)}>
                {mobileDetail.phoneNumber ? <img src={Greentick} alt="Green Tick" className={classes.greenTick} /> : <span className={classes.emptyCircle} />}
                {mobileDetail.phoneNumber ? 'Two-factor authentication is enabled for this account.' : 'Two-factor authentication is not enabled for this account'}
            </Typography>
            <Typography variant="span" className={classes.subtxt}>
                You’ll need to log In using your password and a unique verfication code that will be sent to your registered phone number.
            </Typography>
            <Typography variant="h6" className={clsx(classes.subtitle, classes.setTop)}>
                Registered phone number
            </Typography>
            <Typography variant="span" className={classes.subtxt}>
                {formattedPhoneNumber(mobileDetail?.phoneNumber)}
            </Typography>
        </>
    );
}

export const LoginNSecurity = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { resetPassword } = useAuth();
    const { currentAccessToken } = useAppToken();
    const userId = currentAccessToken ? jwt_decode(currentAccessToken).sub : '';
    const phoneDetails = useSelector(state => state.common.phoneDetails);
    const [notify, setNotify] = useState('');
    const [backdrop, setBackdrop] = useState(false);
    const onNotificationClose = () => {
      setNotify('');
    }

    useEffect(() => {
        if (userId) {
            dispatch(getPhoneNumbers(userId));
        }
    }, [userId]);

    const mobileDetail = phoneDetails.find(ph => ph.phoneType === "mobile");

    const twoFactorListener = (mode) => {
        if (mode === 'Cancel') {
            dispatch(setUpdatedPhoneNumber());
        }
    };
    const resetPasswordWithBackdrop= cancelAction => {
        resetPassword(cancelAction, () => setBackdrop(true), () => setBackdrop(false));
    }

    return (
        <LoginNotificationContext.Provider
            value={{
                setNotify
            }}
        >
            <Backdrop style={{ zIndex: 2000 }} open={backdrop}>
                <Typography>
                    Please complete Password Reset.
                </Typography>
            </Backdrop>
            <div className={classes.root}>
                <SideNav active="Login and security" />
                <Grid xs={12}>
                    <main className={classes.main}>
                        {notify && <NotificationBanner type={notify?.status} notificationMsg={notify?.notificationMsg} onClose={onNotificationClose} />}
                        <Typography variant={'h4'} className={classes.title}>
                            Login & Security
                        </Typography>
                        <Typography variant={'subtitle1'} className={classes.description}>Manage your login credentials, and keep your verification methods and security information up to date.</Typography>

                        <div className={classes.eachCard}>
                            <CardInfo cardTitle="Password" mode="ChangePassword" clickListener={resetPasswordWithBackdrop} />
                        </div>

                        <div className={classes.eachCard}>
                            <CardInfo cardTitle="Two-factor authentication" mode="Edit" displayComponent={<TwoFactorComponent mobileDetail={mobileDetail} />} editComponent={<TwofactorEdit mobileDetail={mobileDetail} />} onModeChange={twoFactorListener} />
                        </div>
                    </main>
                </Grid>
            </div>
        </LoginNotificationContext.Provider>
    );
};