export const keys = {
  token: 'user-profile-token'
};

export const getToken = () => {
  return window.sessionStorage.getItem(keys.token) || '';
}
  

export const setToken = token => {
  return window.sessionStorage.setItem(keys.token, token);
}
  
export const revokeToken = () => {
  window.sessionStorage.removeItem(keys.token);
}
  

