import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import { useAuth } from '../../auth/AuthProvider';

const SecureRoute = ({ path, component, ...rest }) => {
  const { account, loading, ssoLogin } = useAuth();

  useEffect(() => {
    if (!account && !loading) {
      ssoLogin();
    }
  }, [ssoLogin, loading, account]);

  return account ? <Route path={path} component={component} {...rest} /> : null;
};

SecureRoute.propTypes = {
  path: PropTypes.string.isRequired,
  component: PropTypes.oneOfType([PropTypes.object, PropTypes.func])
};

export default SecureRoute;
