import React, { useEffect, useState, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import jwt_decode from "jwt-decode";
import { makeStyles } from '@material-ui/core/styles';
import SideNav from '../../components/SideNav/SideNav';
import NotificationBanner from '../../components/NotificationBanner/NotificationBanner';
import { RouterPrompt } from "../../components/CustomPrompt/CustomPrompt";
import { useAppToken, DrawerContext } from '../../context';
import { FormPristineContext } from '../../context/FormPristineContext';
import { getPersonalInformation, getPersonalSensitiveInfo, getGenderLookup } from '../../redux/personalInformation/actions';
import { getStateList } from '../../redux/common/actions';
import PersonalInfoDisplay from './components/PersonalInfoDisplay';
import CardInfo from './components/CardInfo';
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
const customStyles = (isDrawerOpen) => {
  return makeStyles((theme) => {
    return ({
      root: {
        padding: '48px 8%',
        minWidth: 500,
        minHeight: 440,
        flexGrow: 1,
        fontFamily: 'HCo Whitney SSm',
        [theme.breakpoints.down("xs")]: {
          maxWidth: 320,
          minWidth: 320,
          marginTop: 20,
        },
        '@media (max-width: 320px)':{
          maxWidth: 280,
          minWidth: 280,
          marginTop: 20,
        }
      },
      pageTitle: {
        fontFamily: 'Chronicle Text G1',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: 32,
        color: 'rgba(0, 0, 0, 0.87)',
        [theme.breakpoints.down("xs")]: {
          display: 'none'
        }
      },
      main: {
        marginTop: 56,
        marginLeft: 0,
        [theme.breakpoints.up('sm')]: {
          marginLeft: '240px !important'
        },
      },
      header: {
        fontSize: '2rem',
        marginBottom: '16px',
      },
      helpButton: {
        float: 'right'
      },
      buttonGroup: {
        float: 'right'
      },
      tableContainer: {
        minHeight: '400px',
        maxHeight: '70vh'
      },
      tableCellHead: {
        width: '300px'
      },
      button: {
        backgroundColor: '#186ecc',
        color: '#ffffff',
        margin: '10px 0px 15px 30px',
        '&:hover': {
          backgroundColor: '#186ecc'
        },
        '&:disabled': {
          backgroundColor: '#888',
        }
      },
      footerGroup: {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: '16px'
      },
      bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
      },

      pos: {
        marginBottom: 12,
      },
      heading: {
        fontFamily: 'Chronicle Text G1',
        fontSize: '32px',
        lineHeight: '120%',
        color: 'rgba(0, 0, 0, 0.87)',
        marginBottom: 0,
      },
      container: isRowBased => ({
        width: '100%',
        left: '498px',
        background: '#FFFFFF'
      }),
      boxLeft: {
        float: "left",
        position: "relative",
        backgroundColor: 'rgb(255, 255, 255)',
        borderRadius: '3px',
        border: 'none',
        width: '50%',
        marginTop: '50px',
      },
      boxRight: {
        float: "right",
        position: "relative",
        backgroundColor: 'rgb(255, 255, 255)',
        borderRadius: '3px',
        border: 'none',
        width: '50%',
        marginTop: '50px',
      },

      description: {
        fontFamily: 'HCo Whitney SSm',
        fontSize: '16px',
        lineHeight: '24px',
        color: 'rgba(0, 0, 0, 0.6)',
      },
      headerSection: {
        width: '668px',
        minHeight: '40px',
        background: 'rgba(0, 0, 0, 0.04)',
        opacity: '0.7',
        borderRadius: '3px 3px 0px 0px',
        position: "absolute",
        display: 'block',
      },
      emptyRow: {
        visibility: 'hidden',
        height: '50px',
      },

      eachCard: {
        margin: "30px 0",
        border: '0.5px solid rgba(0, 0, 0, 0.12)',
        boxSizing: 'border-box',
        boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.07), 0px 2px 1px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1)",
      }
    })
  });
}
const useStyles = makeStyles((theme, a, b) => {
  return ({
    root: {
      padding: '48px 8%',
      minWidth: 500,
      minHeight: 440,
      flexGrow: 1,
      fontFamily: 'HCo Whitney SSm',
    },
    pageTitle: {
      fontFamily: 'Chronicle Text G1',
      fontStyle: 'normal',
      fontWeight: 375,
      fontSize: 32,
      color: 'rgba(0, 0, 0, 0.87)'


    },
    main: {
      marginTop: 56,
      marginLeft: '240px'
    },
    header: {
      fontSize: '2rem',
      marginBottom: '16px',
    },
    helpButton: {
      float: 'right'
    },
    buttonGroup: {
      float: 'right'
    },
    tableContainer: {
      minHeight: '400px',
      maxHeight: '70vh'
    },
    tableCellHead: {
      width: '300px'
    },
    button: {
      backgroundColor: '#186ecc',
      color: '#ffffff',
      margin: '10px 0px 15px 30px',
      '&:hover': {
        backgroundColor: '#186ecc'
      },
      '&:disabled': {
        backgroundColor: '#888',
      }
    },
    footerGroup: {
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: '16px'
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },

    pos: {
      marginBottom: 12,
    },
    heading: {
      fontFamily: 'Chronicle Text G1',
      fontSize: '32px',
      lineHeight: '120%',
      color: 'rgba(0, 0, 0, 0.87)',
      marginBottom: 0,
    },
    container: isRowBased => ({
      width: '100%',
      left: '498px',
      background: '#FFFFFF'
    }),
    boxLeft: {
      float: "left",
      position: "relative",
      backgroundColor: 'rgb(255, 255, 255)',
      borderRadius: '3px',
      border: 'none',
      width: '50%',
      marginTop: '50px',
    },
    boxRight: {
      float: "right",
      position: "relative",
      backgroundColor: 'rgb(255, 255, 255)',
      borderRadius: '3px',
      border: 'none',
      width: '50%',
      marginTop: '50px',
    },

    description: {
      fontFamily: 'HCo Whitney SSm',
      fontSize: '16px',
      lineHeight: '24px',
      color: 'rgba(0, 0, 0, 0.6)',
    },
    headerSection: {
      width: '668px',
      minHeight: '40px',
      background: 'rgba(0, 0, 0, 0.04)',
      opacity: '0.7',
      borderRadius: '3px 3px 0px 0px',
      position: "absolute",
      display: 'block',
    },
    emptyRow: {
      visibility: 'hidden',
      height: '50px',
    },

    eachCard: {
      margin: "30px 0",
      border: '0.5px solid rgba(0, 0, 0, 0.12)',
      boxSizing: 'border-box',
      boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.07), 0px 2px 1px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1)",
    }
  })
});

export default () => {
  const { openDrawer } = useContext(DrawerContext);
  const classes = customStyles(openDrawer)();
  const dispatch = useDispatch();
  const { currentAccessToken } = useAppToken();
  // const wholeState = useSelector(state => console.log(state, '^^^^'));
  const personalInfo = useSelector(state => state?.personalInfo?.personInfo);
  const sensitiveInfo = useSelector(state => state?.personalInfo?.sensitiveInfo);
  const genderLookup = useSelector(state => state?.personalInfo?.genderLookup);
  const stateList = useSelector(state => state.common.stateList);
  const getDefaultCardMode = () => (personalInfo?.userData?.address ? 'Edit' : 'New');
  const getDefaultGenderMode = () => (personalInfo?.userData?.sexAndGender && Object.keys(personalInfo.userData.sexAndGender).length ? 'Edit' : 'New');

  const [notify, setNotify] = useState('');
  const [showPrompt, setShowPrompt] = useState(false);
  const [resetData, setResetData] = useState(true);
  const [addressCardMode, setAddressCardMode] = useState(getDefaultCardMode());
  const [genderCardMode, setGenderCardMode] = useState(getDefaultGenderMode());
  const userId = currentAccessToken ? jwt_decode(currentAccessToken).sub : '';

  const onSave = saveStatus => {
    setNotify(saveStatus);
  };

  const onNotificationClose = () => {
    setNotify('');
  }

  useEffect(() => {
    if (resetData) {
      dispatch(getPersonalInformation(userId));
      dispatch(getPersonalSensitiveInfo(userId));
      dispatch(getGenderLookup());
      setResetData(false);
    }
    if (!stateList) {
      dispatch(getStateList());
    }
  }, [resetData, stateList, userId]);

  useEffect(() => {
    if (userId) {
      dispatch(getPersonalInformation(userId));
      dispatch(getPersonalSensitiveInfo(userId));
    }
    dispatch(getGenderLookup());
    dispatch(getStateList())
  }, [currentAccessToken, userId])

  useEffect(() => {
    if (!personalInfo) {
      dispatch(getPersonalInformation(userId));
    }
  }, [currentAccessToken, userId, personalInfo, dispatch]);

  useEffect(() => {
    if (!sensitiveInfo) {
      dispatch(getPersonalSensitiveInfo(userId));
    }
  }, [currentAccessToken, userId, sensitiveInfo, dispatch]);

  useEffect(() => {
    if (!genderLookup) {
      dispatch(getGenderLookup());
    }
  }, [genderLookup, dispatch]);

  useEffect(() => {
    if (!stateList) {
      dispatch(getStateList());
    }
  }, [stateList, dispatch]);

  useEffect(() => {
    setAddressCardMode(getDefaultCardMode());
  }, [personalInfo?.userData?.address]);

  useEffect(() => {
    setGenderCardMode(getDefaultGenderMode());
  }, [personalInfo?.userData?.sexAndGender]);
  
  const handleCardButtonClick = (buttonText, mode) => {
    if(buttonText === "Cancel") {
      getGenderLookup()
      setShowPrompt(false)
    }
  }
  return (
    <FormPristineContext.Provider
      value={{
        setShowPrompt,
        setNotify
      }}
    >
      <div className={classes.root} >
        <RouterPrompt
          when={showPrompt}
          title="Unsaved changes"
          cancelText="Continue Editing"
          okText="Leave"
          onOK={() => true}
          onCancel={() => false}
        />
        <SideNav active="Personal information" />
        <Grid xs={12}>
          <main className={classes.main}>
            {notify && <NotificationBanner type={notify?.status} notificationMsg={notify?.notificationMsg} onClose={onNotificationClose} />}
            <div className={classes.container}>
         
                 <Typography variant="h5" className={classes.pageTitle} key="title">
                  Personal information
                </Typography>


              <div className={classes.eachCard}>
                <CardInfo disabled cardTitle="Personal information" mode="Edit" hideButton
                  displayComponent={<PersonalInfoDisplay userInfo={personalInfo?.userData} dob={sensitiveInfo?.dob} />}
                />
              </div>
            </div>
          </main>
        </Grid>
      </div>
    </FormPristineContext.Provider>
  );
};
