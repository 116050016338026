import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistStore } from 'redux-persist';
import rootReducer from './rootReducer';

// const persistConfig = {
//   key: 'root',
//   storage,
//   blacklist: ['application', 'users']
// };

export const store = createStore(
    rootReducer,
  composeWithDevTools(applyMiddleware(thunk))
);

export const persistor = persistStore(store);
