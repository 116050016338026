import React from 'react';
import moment from 'moment';
import {
  Avatar
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column'
  },
  displayContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 24
  },
  initialCol: {
    marginRight: 31
  },
  initial: {
    color: '#1E69D2',
    backgroundColor: '#E8F5FE',
    width: 62,
    height: 62,
    fontSize: 24,
    fontFamily: 'HCo Whitney SSm',
    fontWeight: 500
  },
  fullName: {
    fontFamily: 'HCo Whitney SSm',
    fontSize: 24,
    color: 'rgba(0, 0, 0, 0.87)',
    marginTop: 20,
    marginBottom: 22
  },
  flabel: {
    marginTop: 24,
    color: 'rgba(0, 0, 0, 0.87)',
    fontFamily: 'HCo Whitney SSm',
  },
  fval: {
    fontSize: 16,
    color: 'rgba(0, 0, 0, 0.6)',
    fontWeight: 350,
    fontFamily: 'HCo Whitney SSm',
  }
}));

export default ({ userInfo = {}, dob }) => {
  const classes = useStyles();
  const { firstName, lastName, primaryEmail, phone = [] } = userInfo;
  const fullName = `${firstName} ${lastName}`;
  const initial = fullName.charAt(0);

  const formaPhNumber = ph => {
    if (!ph) {
      return 'N/A';
    }
    return `(${ph.slice(0, 3)})-${ph.slice(3, 6)}-${ph.slice(6)}`;
  };
  const dateofBirth = (dob === undefined ? '' : moment(dob).format("MM/DD/YYYY"));

  return (<div className={classes.container}>
    <div className={classes.displayContainer}>
      <div className={classes.initialCol}>
        <Avatar className={classes.initial}>{initial}</Avatar>
      </div>
      <div className={classes.col}>
        <div className={classes.fullName}>{fullName}</div>
        <Typography variant={'body1'} className={classes.flabel}>Email</Typography>
        <Typography variant={'body1'} className={classes.fval}>{primaryEmail ? primaryEmail : "N/A"}</Typography>
        <Typography variant={'body1'} className={classes.flabel}>Phone number</Typography>
        <Typography variant={'body1'} className={classes.fval}>{formaPhNumber(!phone ? "":phone[0])}</Typography>
        <Typography variant={'body1'} className={classes.flabel}>Date of birth</Typography>
        <Typography variant={'body1'} className={classes.fval}>
          {dateofBirth}
        </Typography>
      </div>
    </div>
  </div>)
};