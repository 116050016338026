import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
  AppBar,
  Toolbar,
  Button,
  Menu,
  MenuItem,
  ListItemText,
  ListItemIcon
} from '@material-ui/core';
import ascensionLogo from '../../assets/aad-logo.png';
import whiteAscensionLogo from '../../assets/ascen_logo_wht.png';
import userLogo from '../../assets/User.svg';
import routes from '../../constants/routes';
import { useAuth } from '../../auth/AuthProvider';
import LogoutIcon from '../../assets/logout_24dp.png';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';
import { DrawerContext } from '../../context';

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {

  }
}))(MenuItem);

const useStyles = makeStyles(theme => ({
  root: {
    background: 'linear-gradient(216.98deg, #1E69D2 5.25%, #1E69D2 94.64%)',
    zIndex: theme.zIndex.drawer + 1,
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  menuButton: {
    marginRight: theme.spacing(2),
    display: 'none',
    [theme.breakpoints.only('xs')]: {
      display: 'block',
    },
  },
  topLogo: {
    backgroundColor: '#fff',
    padding: '9.5px 0 9.5px 24px',
    display: 'none',
    [theme.breakpoints.only('xs')]: {
      display: 'block',
    },
  },
  brand: {
    display: 'flex',
    [theme.breakpoints.only('xs')]: {
      display: 'none',
    },
    justifyContent: 'center',
    alignItems: 'center',
    color: '#fff',
    float: 'left',
    width: 'auto',
    textDecoration: 'none',
    '& > h4': {
      margin: 0,
      fontSize: '20px'
    }
  },
  divider: {
    height: '30px',
    margin: '0px 60px',
    border: '1px solid #fff'

  },
  locationBtn: {
    backgroundColor: '#ffc333',
    marginRight: '32px',
    '&:hover': {
      backgroundColor: '#ffd266'
    }
  },
  displayName: {
    color: '#000000;',
    textTransform: 'none',
    height: '50px',
    alignItems: 'center',
    justifyContent: 'center',
    display: "flex",
  },
  profileButton: {
    color: '#fff',
    '& img': {
      marginRight: 12
    }
  },
  expandIcon: {
    marginLeft: 10
  },
  logo: {
    display: "flex",
    float: "left",
  },
  name: {
    fontStyle: "normal",
    fontWeight: 375,
    fontSize: '14px',
    lineHeight: '16px',
    textAlign: 'center',
    letterSpacing: '1.25px',
    textTransform: 'uppercase',
    color: '#FFFFFF',
    float: 'right',
    paddingLeft: '5px',
    margin: '0 auto',
  },
  logoutTxt: {
    color: 'rgba(0, 0, 0, 0.87)'
  },
  logouticon: {
    width: 20,
    height: 20,
    color: 'rgba(0, 0, 0, 0.87)'
  }
}));

export const Banner = () => {
  const {openDrawer, setOpenDrawer} = useContext(DrawerContext);
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const { account, logout } = useAuth();

  const getDisplayName = acc => ((acc?.idTokenClaims?.firstName || acc?.idTokenClaims?.given_name) || 'User');
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDrawerToggle = () => {
    setOpenDrawer(!openDrawer);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logMeout = () => {
    logout();
  };

  return (<AppBar className={classes.root}>
    <Link to={routes.HOME_PAGE} className={classes.topLogo}>
      <img src={ascensionLogo} height="34px" alt="brand" />
    </Link>
    <Toolbar className={classes.toolbar}>
      <div>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          className={classes.menuButton}
        >
          <MenuIcon />
        </IconButton>
        <Link to={routes.HOME_PAGE} className={classes.brand}>
          <img src={whiteAscensionLogo} height="34px" alt="brand" />
        </Link>
      </div>

      <div className={classes.displayName}>
        <Button
          className={classes.profileButton}
          aria-controls="customized-menu"
          data-testid="profile-btn"
          aria-haspopup="true"
          onClick={handleClick}
        >
          <img src={userLogo} alt="ascension" />
          {getDisplayName(account)}
          {anchorEl ? <ExpandLessIcon data-testid="expand-less" className={classes.expandIcon} /> : <ExpandMoreIcon data-testid="expand-more" className={classes.expandIcon} />}
        </Button>
        <StyledMenu
          id="customized-menu"
          data-testid="customized-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <StyledMenuItem onClick={logMeout} data-testid="logout-menu">
            <ListItemIcon>
              <img className={classes.logouticon} src={LogoutIcon} alt="logout" />
            </ListItemIcon>
            <ListItemText className={classes.logoutTxt} primary="Log out" />
          </StyledMenuItem>
        </StyledMenu>
      </div>
    </Toolbar>
  </AppBar>
  );
};

export default Banner;
