import axios from 'axios';
import constants from '../../constants/redux.constants';
import { getToken } from '../../utils/session-utils';
import { getEnvVars } from '../../utils/env-utils';

const { apiUrl } = getEnvVars(window.location.hostname);
const { apiKey } = getEnvVars(window.location.hostname);
const { SET_PERSONAL_INFORMATION, SET_PERSONAL_INFO, SET_PERSONAL_INFO_ERROR, SET_SENSITIVE_INFO, SET_SENSITIVE_INFO_ERROR, SET_GENDER_LOOKUP } = constants;

const setPersonalInfo = payload => ({
  type: SET_PERSONAL_INFO,
  payload
});

const setPersonalInfoError = payload => ({
  type: SET_PERSONAL_INFO_ERROR,
  payload
});

const setSensitiveInfo = payload => ({
  type: SET_SENSITIVE_INFO,
  payload
});

const setSensitiveInfoError = payload => ({
  type: SET_SENSITIVE_INFO_ERROR,
  payload
});

export const getPersonalInformation = userId => {
  const accessToken = getToken();
  return dispatch => {
    return axios
      .get(`${apiUrl}/v1/profiles/${userId}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          apiKey: `${apiKey}`
        }
      }).then(
        (res) => dispatch(setPersonalInfo(res)),
        (error) => dispatch(setPersonalInfoError(error))
      );
  };
}

export const getPersonalSensitiveInfo = userId => {
  const accessToken = getToken();
  return dispatch => {
    return axios
      .get(`${apiUrl}/v1/profiles/${userId}/sensitivedata`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          apiKey: `${apiKey}`
        }
      }).then(
        (res) => dispatch(setSensitiveInfo(res)),
        (error) => dispatch(setSensitiveInfoError(error))
      );
  };
};

export const setPersonalInformation = payload => ({
  type: SET_PERSONAL_INFORMATION,
  payload
});

export const saveProfileInfo = (userId, personalInfoPayload, cb) => {
  const accessToken = getToken();
  return dispatch => {
     axios.put(`${apiUrl}/v1/profiles/${userId}/userdata`, personalInfoPayload, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        apiKey: `${apiKey}`
      }
    }).then(res => {
      dispatch(getPersonalInformation(userId));
      cb('success');
    }, error => {
      cb('error', error);
    });
  }
};

export const savePersonalInformation = (userId, personalInfoPayload, sensitiveDataPayload, cb) => {
  const accessToken = getToken();
  return dispatch => {
    const saveProfileInfoCall = axios
      .put(`${apiUrl}/v1/profiles/${userId}/userdata`, personalInfoPayload, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          apiKey: `${apiKey}`
        }
      });
    const saveSensitiveCall = axios
      .put(`${apiUrl}/v1/profiles/${userId}/sensitivedata`, sensitiveDataPayload, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          apiKey: `${apiKey}`
        }
      });

    Promise.all([saveProfileInfoCall, saveSensitiveCall]).then(
      (res) => {
        dispatch(getPersonalInformation(userId));
        dispatch(getPersonalSensitiveInfo(userId));
        cb('success');
      },
      (error) => {
        cb('error', error);
      }
    );
  }
};

export const setGenderLookup = payload => ({
  type: SET_GENDER_LOOKUP,
  payload
});

export const getGenderLookup = () => {
  const accessToken = getToken();
  return dispatch => {
    return axios
      .get(`${apiUrl}/v1/lookups/SexAndGender`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          apiKey: `${apiKey}`
        }
      }).then(
        res => dispatch(setGenderLookup(res?.data?.lookupItems || {})),
        error => dispatch(setGenderLookup({}))
      );
  };
};
