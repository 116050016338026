import { createContext, useContext } from 'react';

export const PersonalInfoContext = createContext(null);

export const AppTokenContext = createContext(null);

export const DrawerContext = createContext(false);

export const LoginNotificationContext = createContext(null);

export const useAppToken = () => useContext(AppTokenContext);
