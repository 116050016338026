import React, { useState, useEffect, useRef } from 'react';
import clsx from 'clsx';
import jwt_decode from "jwt-decode";
import { useSelector, useDispatch } from 'react-redux';
import { Button, InputLabel, TextField, Typography, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AppConstant from "../../constants/app.constant";
import Timer from "../Timer/Timer";
import { RouterPrompt } from "../../components/CustomPrompt/CustomPrompt";
import ErrorIcon from '@material-ui/icons/Error';
import Greentick from '../../assets/green-tick.svg';
import { sendVerificationCode, addPhoneNumbers, setVerificationCode } from '../../redux/common/actions';
import ReduxConstant from "../../constants/redux.constants";
import { useAppToken, LoginNotificationContext, PersonalInfoContext } from '../../context';
import { formattedPhoneNum } from '../../utils/test-utils';

const { VERIFICATION_RETRY_COUNT } = AppConstant;
const { SET_VERIFICATION_CODE } = ReduxConstant;

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '48px 8%',
    minWidth: 500,
    minHeight: 440,
    flexGrow: 1,
    fontFamily: 'HCo Whitney SSm',
  },
  main: {
    marginLeft: '385px',
    width: 807,
  },
  header: {
    fontSize: '2rem',
    marginBottom: '16px',
  },
  tableContainer: {
    minHeight: '100px',
    maxHeight: '70vh',
    borderBottom: 0,
  },
  title: {
    marginTop: 56,
    fontFamily: 'Chronicle Text G1',
    fontStyle: 'normal',
    fontWeight: 375,
    fontSize: 32,
    color: 'rgba(0, 0, 0, 0.87)'
  },
  container: {
    display: 'block',
    width: '100%',
    left: '498px',
    marginTop: '25px',
    borderRadius: '3px',
    border: '0.5px solid rgba(0, 0, 0, 0.12)',
    boxSizing: 'border-box',
    boxShadow: '1px 1px 1px rgb(0 0 0 / 7%), 0px 2px 1px rgb(0 0 0 / 6%), 0px 1px 3px rgb(0 0 0 / 10%)',
    background: '#FFFFFF',
  },
  description: {
    fontFamily: 'Whitney',
    fontSize: 16,
    lineHeight: '24px',
    color: 'rgba(0, 0, 0, 0.6)',
    fontWeight: 350
  },
  eachCard: {
    margin: "32px 0"
  },
  subtitle: {
    fontSize: 16
  },
  subtxt: {
    fontStyle: 'normal',
    fontWeight: 350,
    color: 'rgba(0, 0, 0, 0.6)'
  },
  setTop: {
    marginTop: 24
  },
  greenTick: {
    marginRight: 9
  },
  alignCenter: {
    display: 'flex'
  },
  emptyCircle: {
    display: 'inline-block',
    width: 20,
    height: 20,
    borderRadius: 50,
    border: '1px solid #ccc',
    marginRight: 9
  },
  sendVerifCode: {
    textTransform: 'uppercase'
  },
  formPhLabel: {
    marginBottom: 8,
    color: "#000"
  },
  verificationCode: {
    letterSpacing: 3.15,
    '& p': {
      fontSize: "12px",
      lineHeight: "16px",
      letterSpacing: "0.4px",
      color: "#E00019"
    }
  },
  footerBtn: {
    marginTop: 30
  },
  editRow: {
    marginTop: 5,
    maxWidth: "25rem"
  },
  authDesc: {
    display: "block",
    fontSize: 16,
    color: "#000"
  },
  editPhNum: {
    textTransform: "none",
    textDecoration: 'underline',
    marginTop: 20,
    paddingLeft: 0
  },
  timerContainer: {
    marginTop: 24
  },
  dintReceiveContainer: {
    display: "flex",
    flexDirection: "row",
    marginTop: 8,
    marginBottom: 16
  },
  resendBtn: {
    textTransform: "none",
    textDecoration: 'underline',
    padding: 0
  },
  exceedHldr: {
    width: "22rem"
  },
  exceedContainer: {
    border: "1px solid #E00019",
    borderLeftWidth: 4,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start"
  },
  exceedIcon: {
    color: "#E00019",
    padding: 18,
    paddingRight: 0
  },
  exceedMsg: {
    display: "inline-block",
    padding: 18,
    letterSpacing: 0.5
  },
  reqNewCode: {
    marginTop: 34,
    width: "100%"
  }
}));

const verificationCodeErrMsg = {
  "invalid": "Phone number is invalid. Please enter correct phone number and try again."
};

export const TwofactorEdit = ({ mobileDetail = {} }) => {
  let phoneRef;
  const classes = useStyles();
  const dispatch = useDispatch();
  const updatedPhNum = useSelector(state => state.common.updatedPhoneNumber);
  const verificationCode = useSelector(state => state.common.verificationCode);
  const getFormattedPhNum = (rawPhNum) => {
    const formattedPhNum = rawPhNum.replace("+1", "").replace(/[^\w\s]/gi, '').trim();
    return formattedPhNum;
  }

  const [registeredPh, setRegisteredPh] = useState(getFormattedPhNum(updatedPhNum || mobileDetail.phoneNumber || ''));
  const [isFieldDirt, setIsFieldDirt] = useState(false);
  const [errMsg, setErrMsg] = useState('');

  const phoneNumListener = (e) => {
    setRegisteredPh(e.target.value);
    if (!isFieldDirt) {
      setIsFieldDirt(true);
    }
  };

  const isInValidPhNum = () => {
    return (isNaN(registeredPh) || registeredPh.length !== 10);
  };

  const onSendVerificationCodeSuccess = () => {
    setErrMsg("");
  };

  const onSendVerificationCodeFailure = () => {
    setErrMsg(verificationCodeErrMsg.invalid);
  };

  const onSendVerificationCode = () => {
    const rawPhNum = registeredPh.replace(/[^\w\s]/gi, '');
    dispatch(sendVerificationCode(rawPhNum, onSendVerificationCodeSuccess, onSendVerificationCodeFailure));
  };

  useEffect(() => {
    return () => {
      dispatch(setVerificationCode(""));
    };
  }, []);

  if (verificationCode) {
    return <AuthenticationRequired registeredNumber={registeredPh} />
  }

  return (
    <>
      <RouterPrompt
        when={isFieldDirt}
        title="Unsaved changes"
        cancelText="Continue Editing"
        okText="Leave"
        onOK={() => true}
        onCancel={() => false}
      />
      <Typography variant="h6" className={clsx(classes.subtitle, classes.alignCenter)} data-testid="sub-title">
        {mobileDetail.phoneNumber ? <img src={Greentick} alt="Green Tick" className={classes.greenTick} /> : <span className={classes.emptyCircle} />}
        {mobileDetail.phoneNumber ? 'Two-factor authentication is enabled for this account.' : 'Two-factor authentication is not enabled for this account'}
      </Typography>
      <Typography variant="span" className={classes.subtxt}>
        You’ll need to log In using your password and a unique verfication code that will be sent to your registered phone number.
      </Typography>
      <div className={classes.editRow}>
        <InputLabel className={classes.formPhLabel} htmlFor="reg-ph-no" variant="standard">Registered phone number</InputLabel>
        <TextField className={clsx(classes.formInputInline, classes.zipCode)} id="reg-ph-no" defaultValue={formattedPhoneNum(registeredPh)} variant="outlined" inputRef={el => phoneRef = el} onChange={phoneNumListener} error={isInValidPhNum() || errMsg} data-testid="reg-ph-num" autoFocus helperText={errMsg} />
      </div>
      <div className={classes.footerBtn}>
        <Button className={classes.sendVerifCode} variant="contained" color="primary" type="button" disabled={isInValidPhNum()} onClick={onSendVerificationCode} data-testid="send-verification-code">
          Send verification code
        </Button>
      </div>
    </>
  );
};

export default TwofactorEdit;

const verificationErrMessage = {
  "required": "Please enter the verification code that was sent to your phone number and try again",
  "invalid": "Invalid code! Please enter the correct verification code that was sent to your phone number"
}

const AuthenticationRequired = ({ registeredNumber }) => {
  const verificationCodeRef = useRef(null);
  const getCountDownDate = () => (new Date().getTime() + 5 * 60 * 1000 + 1000);

  const dispatch = useDispatch();
  const classes = useStyles();
  const { currentAccessToken } = useAppToken();
  const userId = currentAccessToken ? jwt_decode(currentAccessToken).sub : '';
  const receivedCode = useSelector(state => state.common.verificationCode);
  const [verificationCode, setVerificationCode] = useState();
  const [misEntries, setMisEntries] = useState(0);
  const [errMsg, setErrMsg] = useState('');
  const [isExceededAttempts, setIsExceededAttempts] = useState(false);
  const [isExceededTimelimit, setIsExceededTimelimit] = useState(false);
  const [resetTimer, setResetTimer] = useState(getCountDownDate());
  const [isAuthfieldDirt, setIsAuthfieldDirt] = useState(false);

  const onEditPhNum = () => {
    dispatch({
      type: SET_VERIFICATION_CODE,
      payload: ""
    });
  };

  const isValidVerCode = () => {
    return (receivedCode === verificationCode);
  };

  const verificationCodeListener = (e) => {
    setVerificationCode(e.target.value);
    if (!isAuthfieldDirt) {
      setIsAuthfieldDirt(true);
    }
  };

  const onResend = () => {
    setResetTimer(getCountDownDate());
    setIsExceededAttempts(false);
    setIsExceededTimelimit(false);
    setVerificationCode("");
    setErrMsg("");
    dispatch(sendVerificationCode(registeredNumber));
    setTimeout(() => {
      verificationCodeRef?.current?.focus();
    }, 400);
  }

  const onVerify = (setNotify, setMode) => {
    if (verificationCode === undefined || !verificationCode?.trim()) { /* Required Validation */
      if (verificationCode === undefined) {
        setVerificationCode("");
      }
      setErrMsg(verificationErrMessage.required);
    } else if (receivedCode !== verificationCode) { /* Invalid Validation */
      const updatedMisEntries = misEntries + 1;
      setMisEntries(updatedMisEntries);
      setErrMsg(verificationErrMessage.invalid);
      if (updatedMisEntries >= VERIFICATION_RETRY_COUNT) {
        setIsExceededAttempts(true);
      }
    } else {
      setErrMsg("");
      const payload = {
        "phoneNumber": registeredNumber,
        "phoneType": "mobile"
      };
      dispatch(addPhoneNumbers(payload, userId, (successRes) => {
        setMode("Edit");
        setNotify({
          status: "success",
          notificationMsg: "Your registered phone number has been updated successfully!"
        });
      }, (errRes) => {
        setMode("Edit");
        setNotify({
          status: "error",
          notificationMsg: "Add phone number service failed"
        });
      }));
    }
  };

  const requestNewCode = () => {
    onResend();
    setMisEntries(0);
    setIsExceededAttempts(false);
    setIsExceededTimelimit(false);
    setVerificationCode();
    setErrMsg("");
  };

  const onTimerExpire = () => {
    setIsExceededAttempts(true);
    setIsExceededTimelimit(true);
  };

  useEffect(() => {
    verificationCodeRef?.current?.focus();
  }, []);

  return (
    <LoginNotificationContext.Consumer>
      {
        ({ setNotify }) => (
          <PersonalInfoContext.Consumer>
            {
              ({ setMode}) => (
                <>
                  <RouterPrompt
                    when={isAuthfieldDirt}
                    title="Unsaved changes"
                    cancelText="Continue Editing"
                    okText="Leave"
                    onOK={() => true}
                    onCancel={() => false}
                  />
                  {registeredNumber && <Typography variant="div" className={classes.authDesc}>
                    We’ve sent a verification code to {registeredNumber}. Please enter it here.
                  </Typography>}
                  <Button color="primary" onClick={onEditPhNum} className={classes.editPhNum} data-testid="edit-ph-num">Edit phone number</Button>
                  {!isExceededAttempts && <>
                    <div className={classes.editRow}>
                      <InputLabel className={classes.formPhLabel} htmlFor="ver-code" variant="standard">Verification code</InputLabel>
                      <TextField className={classes.verificationCode} id="ver-code" value={verificationCode} variant="outlined" inputRef={verificationCodeRef} onChange={verificationCodeListener} error={verificationCode !== undefined && !isValidVerCode()} helperText={errMsg} data-testid="ver-code" autoFocus />
                    </div>
                    <div className={classes.timerContainer}>
                      <Typography>
                        Verification code will expire in <Timer onExpire={onTimerExpire} resetTimer={resetTimer} />
                      </Typography>
                    </div>
                    <div className={classes.dintReceiveContainer}>
                      <Typography variant="div" className={classes.dintReceive}>
                        Didn’t receive the code?
                      </Typography>
                      <Button color="primary" className={classes.resendBtn} data-testid="resend-btn" onClick={onResend}>Resend</Button>
                    </div>
                    <div>
                      <Button className={classes.sendVerifCode} variant="contained" color="primary" type="button" data-testid="verify-btn" onClick={() => { onVerify(setNotify, setMode) }}>
                        Verify
                      </Button>
                    </div>
                  </>}
                  {
                    isExceededAttempts && <Grid item direction="column" classes={classes.exceedHldr} xs={6}>
                      <Grid item>
                        <div className={classes.exceedContainer}>
                          <ErrorIcon className={classes.exceedIcon} />
                          <Typography variant="span" className={classes.exceedMsg}>
                            {isExceededTimelimit ? 'You’ve exceeded the time limit to enter the verification code. Please request a new code and try again.' : 'You’ve exceeded the number of incorrect attempts. Please request a new code and try again.'}
                          </Typography>
                        </div>
                      </Grid>
                      <Grid item classes={classes.reqNewCodeHldr}>
                        <Button className={classes.reqNewCode} variant="contained" color="primary" type="button" onClick={requestNewCode} data-testid="req-new-code">
                          Request New Code
                        </Button>
                      </Grid>
                    </Grid>
                  }
                </>)}
          </PersonalInfoContext.Consumer>
        )
      }
    </LoginNotificationContext.Consumer>
  );
}
