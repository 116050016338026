import { useEffect, useState } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { useAuth } from '../auth/AuthProvider';
import routes from '../constants/routes';
import SecureRoute from '../components/SecureRoute/SecureRoute';
import Banner from '../components/Banner/Banner';
import Logout from '../components/Logout/Logout';
import PersonalInformation from "../screens/PersonalInformation";
import { LoginNSecurity } from "../screens/LoginNSecurity/LoginNSecurity";
import { setToken } from '../utils/session-utils';
import { AppTokenContext, DrawerContext } from '../context';
import Interceptor from './interceptor';
import '@ascension/ui-library/dist/fonts/WhitneySSm.css';
import '@ascension/ui-library/dist/fonts/ChronicleSSm.css';

export default () => {
  const { aquireToken, account, logout } = useAuth();
  const [currentAccessToken, setCurrentAccessToken] = useState(null);
  const [openDrawer, setOpenDrawer] = useState(false);
  useEffect(() => {
    new Interceptor({ aquireToken, logout });
  }, [aquireToken, logout]);

  useEffect(() => {
    if (account) {
      aquireToken()
        .then((res) => {
          const { accessToken } = res || {};
          if (accessToken) {
            setCurrentAccessToken(accessToken);
            setToken(accessToken);
          }
        })
        .catch(err => console.log(err));
    }
  }, [
    aquireToken, account
  ]);

  return (
    <AppTokenContext.Provider
      value={{ currentAccessToken }}>
        <DrawerContext.Provider value={{ openDrawer, setOpenDrawer }}>
        <BrowserRouter
          getUserConfirmation={() => {
            /* Empty callback to block the default browser prompt */
          }}
        >
          <Banner />
          {
            <Switch>
              <SecureRoute exact path={routes.HOME_PAGE} component={LoginNSecurity} />
              <Route path={routes.LOGOUT} component={Logout} />
            </Switch>
          }
        </BrowserRouter>
      </DrawerContext.Provider>
    </AppTokenContext.Provider>
  );
}

