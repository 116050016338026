import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router";
import clsx from 'clsx';
import { Modal, Paper, Button, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  close: {
    float: 'right'
  },
  modal: {
    display: 'flex',
    padding: theme.spacing(1),
    alignItems: 'center',
    justifyContent: 'center',
  },
  popup: {
    padding: '24px',
    textAlign: 'left',
    maxWidth: 409,
    fontFamily: 'HCo Whitney SSm',
    fontStyle: 'normal',
    fontWeight: 375,
  },
  title: {
    fontSize: 20,
    margin: "0 024px0 0",
    color: 'rgba(0, 0, 0, 0.87)'
  },
  modalContent: {
    fontFamily: 'HCo Whitney SSm',
    fontStyle: 'normal',
    fontWeight: 350,
    fontSize: 16,
    color: 'rgba(0, 0, 0, 0.6)'
  },
  footerContainer: {
    float: 'right'
  },
  footerbtn: {
    fontSize: 14,
    textTransform: 'uppercase'
  },
  okBtn: {
    color: '#E00019',
  },
  cancelBtn: {
    color: '#1E69D2'
  }
}));

export function RouterPrompt(props) {
  const { when, onOK, onCancel, title, okText, cancelText } = props;
  const classes = useStyles();
  const history = useHistory();
  const [showPrompt, setShowPrompt] = useState(false);
  const [currentPath, setCurrentPath] = useState("");

  useEffect(() => {
    if (when) {
      history.block((prompt) => {
        setCurrentPath(prompt.pathname);
        setShowPrompt(true);
        return "true";
      });
    } else {
      history.block(() => {
        setCurrentPath('');
      });
    }

    return () => {
      history.block(() => {
        setCurrentPath('');
      });
    };
  }, [history, when]);

  const onBlock = () => {
    console.log('Its blocked');
  }

  const handleOK = useCallback(async () => {
    if (onOK) {
      const canRoute = await Promise.resolve(onOK());
      if (canRoute) {
        history.block(onBlock);
        history.push(currentPath);
      }
    }
  }, [currentPath, history, onOK]);

  const handleCancel = useCallback(async () => {
    if (onCancel) {
      const canRoute = await Promise.resolve(onCancel());
      if (canRoute) {
        history.block(onBlock);
        history.push(currentPath);
      }
    }
    setShowPrompt(false);
  }, [currentPath, history, onCancel]);

  return showPrompt ? (
    <Modal
        open={showPrompt}
        className={classes.modal}
        onClose={handleCancel}
        disableBackdropClick
      >
        <Paper className={classes.popup}>
          <h3 className={classes.title}>{title}</h3>
          <p className={classes.modalContent}>
            You have unsaved changes that will be lost if you choose to leave this page. Do you still want to leave this page ?
          </p>
          <div className={classes.footerContainer}>
            <Button color="secondary" onClick={handleOK} className={clsx(classes.footerbtn, classes.okBtn)}>{okText}</Button>
            <Button color="primary" onClick={handleCancel} className={clsx(classes.footerbtn, classes.cancelBtn)}>{cancelText}</Button>
          </div>
        </Paper>
      </Modal>
  ) : null;
}