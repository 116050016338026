import React, { useEffect } from 'react';
import clsx from 'clsx';
import {
  Card,
  Typography,
} from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import WarningIcon from '@material-ui/icons/Warning';
import ErrorIcon from '@material-ui/icons/Error';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';

const autoCloseTime = 5000;

const configs = {
  success: {
    msg: 'Your address was updated successfully',
    colorCode: '#126E36',
    bannerIcon: CheckCircleIcon
  },
  error: {
    msg: 'We’re facing some temporary technical issues and are unable to update your address at the moment. Please try again later.',
    colorCode: '#E00019'
  },
  warning: {
    msg: '',
    colorCode: '#DB6200',
    bannerIcon: WarningIcon
  }
}

const useStyles = makeStyles((theme) => ({
  root: {
    border: `1px solid ${configs.success.colorCode}`,
    borderLeftWidth: 4,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '16px',
    position: 'sticky',
    top: 66,
    [theme.breakpoints.only('xs')]: {
      top: 120
    },
      backgroundColor: 'rgba(255, 255, 255, 1.0)',
    zIndex: 1
  },
  errorCode: {
    borderColor: configs.error.colorCode
  },
  warnCode: {
    borderColor: configs.warning.colorCode
  },
  leftContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  successIcon: {
    color: configs.success.colorCode,
    marginRight: 21
  },
  errIcon: {
    color: configs.error.colorCode,
    marginRight: 21
  },
  warnIcon: {
    color: configs.warning.colorCode,
    marginRight: 21
  },
  close: {
    cursor: 'pointer'
  }
}));

const NotificationBanner = ({
  type,
  notificationMsg,
  onClose,
  overWriteStyle = {}
}) => {
  const classes = useStyles(type);
  let msg = notificationMsg || 'We’re facing some temporary technical issues and are unable to update your address at the moment. Please try again later.';
  let bannerIcon = <ErrorIcon className={classes.errIcon} />;
  let rootStyle = classes.errorCode;

  if (type === 'success') {
    bannerIcon = <CheckCircleIcon className={classes.successIcon} />;
    msg = notificationMsg || 'Your data was added successfully';
    rootStyle = {};
  } else if (type === 'warn') {
    bannerIcon = <WarningIcon className={classes.warnIcon} />;
    msg = notificationMsg || '';
    rootStyle = classes.warnCode;
  }

  useEffect(() => {
    if (onClose) {
      const autoCloseTimer = setTimeout(() => {
        onClose();
      }, autoCloseTime);
      return () => {
        clearTimeout(autoCloseTimer);
      };
    }
  });
  
  
  return (
    <Card className={clsx(classes.root, rootStyle)} style={overWriteStyle} variant="outlined">
      <span className={classes.leftContainer}>
        {bannerIcon}
        <Typography>{msg}</Typography>
      </span>
      {!!onClose && <CloseIcon className={classes.close} onClick={onClose} />}
    </Card>
  );
};
export default NotificationBanner;
